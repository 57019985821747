import styled from 'styled-components';
import akidoLogo from '../../../assets/images/akido_logo.png';
import { UserMenu } from '../UserMenu';
import { CapellaMyQButton } from '../CapellaMyQButton';
import { CapellaERXIntegration } from '../CapellaERXIntegration';

const TopHeader = styled.div`
  padding: 15px 0px 15px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 280px;
`;

export function PageHeaderV2(props: { shouldAutoSaveOnLogout: boolean }) {
  return (
    <TopHeader>
      <div>
        <img src={akidoLogo} alt='Akido logo' />
      </div>
      <RightHeader>
        <CapellaERXIntegration />
        <CapellaMyQButton />
        <UserMenu
          shouldAutoSaveOnLogout={props.shouldAutoSaveOnLogout}
          styles={{ container: { marginRight: '25px' } }}
        />
      </RightHeader>
    </TopHeader>
  );
}
